@use 'sass:map';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$grid-gutter-width: 16px;
$container-width: calc(90vw);
$container-width-max: calc(#{map.get($grid-breakpoints, 'xxl') * 0.9});

$col-width: calc((#{$container-width} - (#{$grid-gutter-width} * 12)) / 12);
$col-width-max: calc((#{$container-width-max} - (#{$grid-gutter-width} * 12)) / 12);

@function col-width($cols) {
    @return calc((#{$col-width} * #{$cols}) + (#{$grid-gutter-width} * #{$cols - 1}));
}

@function col-width-max($cols) {
    @return calc((#{$col-width-max} * #{$cols}) + (#{$grid-gutter-width} * #{$cols - 1}));
}
