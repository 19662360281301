.page-wrapper {
    a {
        font-weight: $font-weight-semibold;
        text-decoration: underline transparent;
        color: var(--color-text);
        transition: color 150ms ease-out, text-decoration-color 150ms ease-out;

        &:hover,
        &:focus {
            text-decoration-color: currentColor;
        }
    }

    .link {
        &__subtle {
            font-weight: inherit;
            text-decoration: none;
            color: inherit;

            &:hover,
            &:focus {
                color: var(--color-brand);
            }
        }

        &__strong {
            font-weight: $font-weight-bold;
            text-decoration: none;
            color: inherit;

            &:hover,
            &:focus {
                color: var(--color-brand);
            }
        }
    }
}
