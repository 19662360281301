@use 'sass:math';

.page-wrapper {
    table {
        width: 100%;
        border-spacing: 0;
        margin: 0 0 1em;
        background-color: white;

        td, th {
            padding: 0.7857em 1em;
        }

        th {
            text-align: left;
            padding-top: 0;
            vertical-align: bottom;
            color: $color-text;
            font-weight: $font-weight-semibold;
        }

        td {
            vertical-align: top;

            .icon {
                position: relative;
                top: -0.1428em;

                &__valid {
                    color: $color-success;
                }

                &__invalid {
                    color: $color-error;
                }

                &__draft {
                    color: mix($color-text-subtle, $color-text, 75%);
                }
            }
        }

        tr:nth-last-child(n+2) {
            td {
                border-bottom: 1px solid var(--color-border-subtle);
            }
        }
        tr:nth-last-child(n+2) {
            th {
                border-bottom: 2px solid var(--color-brand-dark);
            }
        }
    }

    .table {
        &--cell {
            &__narrow {
                width: 0;
            }

            &__date {
                width: 18ch;
            }
        }

        &__simple {
            tr:nth-last-child(n+2) td {
                border-bottom: 1px solid $color-brand-dark;
            }

            tbody tr:nth-child(odd), > tr:nth-child(even) {
                background: transparent;
            }

            td,
            th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        &__report {
            tr:nth-last-child(n+2) {
                th {
                    border-bottom: none;
                }
            }
            th {
                text-transform: uppercase;
                font-size: x-small;
                color: var(--color-text-subtle);
                height:0.5em;
            }
            tr {
                color: var(--color-text);
                padding:0.3em 0.5em
            }
        }
    }
}
