@use 'sass:map';

$icons: (
    chevron-up: '˄',//2303
    chevron-down: '˅',//2304
    chevron-left: '‹', //2039
    chevron-right: '›',  //203a
    double-chevron-left: '≪', //226A
    double-chevron-right: '≫', //226B
    close: '╳',//2573
    edit: '✎', //270e
    delete: '␡', //2421
    tick: '✓', //2713
    warning: '⚠', //26a0
    logout: '⎋', //238b
    calendar: '⊡', // 22a1
    calendar-active:'⊞', // 229E
    play: '▶', //25BA
    email: '◛', //25DB
    email-reply:'✉', //22a0
    message: '○', //
    view: '◉', //25c9
    editor-bold: 'Ⓑ', //24B7
    editor-italic: 'ⅈ',//2148
    editor-underline: '⎁',//2381
    editor-ordered-list: '☳', //2633
    editor-bullet-list: '☰', //2630
    editor-link: '⚭', //26AD
    editor-image: '⛰', //26F0
    send-reply: '⊠', //22a0
    archive: '⌧', //2327
    notification: '␇', //2407
    notification-active:'⍾', //237e
    enquiries: '⎚', //239A
    external-links:'⇴', //21F4
    search:'⚲', //26b2
    filter:'≣', //2263
    download: '⊻', //22BB
    send: '≥',//2265
    card-swap:'⟳',
);

@mixin icon($icon: false) {
    @if $icon {
        content: map.get($icons, $icon);
    }

    display: inline-block;
    font-style: normal;
    font-size: 1em;
    line-height: 1;
    font-family: icons;
    vertical-align: bottom;
}
