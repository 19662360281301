@use 'sass:math';

.page-wrapper {
    button {
        padding: 0;
        border: none;
        background: transparent;
        font: inherit;
        cursor: pointer;
    }
    .btngroup {
        white-space: nowrap;
        display: inline; // ?
        float: right;
        margin: 0 0 1em 0;

        &--btn { // sass bem is cool
            font-size: 1rem;
            letter-spacing: .1em;
            border-radius:0 !important;
            //border: 2px solid var(--color-accent) !important;
            display: inline-block;
            padding: .5em 1.25em;
            outline: 0;
            margin-right: 0.2rem !important;

            &:first-child {
                border-radius: 0.5555em 0 0 0.5555em !important;
            }

            &:last-child {
                border-radius: 0 0.5555em 0.5555em 0 !important;
            }

            //&:link {
            //    color: #bdc3c7;
            //}
            //
            //&:visted {
            //    color: #bdc3c7;
            //}
            //
            //&:hover {
            //    background: #34495e;
            //    color: #fff;
            //}
            //
            //&:active {
            //    background: #fff;
            //    color: #34495e;
            //}
        }
    }
    .btn {
        display: inline-block;
        position: relative;
        padding: 0.4444em 1.2222em;
        border: 1px solid transparent;
        border-radius: 0.5555em;
        background: var(--color-accent);
        color: var(--color-text-light);
        line-height: $line-height;
        font-weight: $font-weight-semibold;
        text-decoration: none;
        cursor: pointer;
        transition: color 150ms $ease-out-cubic, background 150ms $ease-out-cubic, border-color 150ms $ease-out-cubic, filter 150ms $ease-out-cubic;
        filter: drop-shadow(0.05rem 0.45rem 0.4rem rgba($color-accent, 0.5));
        margin: 0 0 1em 0;
        .icon,
        [class*='icon__'] {
            margin: 0 -0.5ch 0 0.5ch;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 0.5em);
            left: calc(50% - 0.5em);
            height: 0;
            width: 1em;
            font-size: 1.2307em;
            opacity: 0;
            background: url('/images/loading-white.svg');
        }

        &__large {
            font-size: 1.4444em;
        }

        &__loading {
            color: transparent !important;
            cursor: default;

            &::after {
                height: 1em;
                opacity: 1;
                transition: opacity 150ms ease-out;
            }
        }

        &:hover,
        &:focus {
            background: var(--color-accent-dark);
        }

        &:active {
            background: var(--color-accent-dark);
            filter: drop-shadow(0.05rem 0.45rem 0.4rem rgba($color-accent, 0.5));
        }

        &__secondary {
            background-color: var(--color-brand);
            color: inherit;

            &:hover,
            &:focus, &:active {
                background-color: var(--color-brand-dark);
            }
        }
        &__tertiary {
            background:var(--color-tertiary);
            color: white;

            &:hover,
            &:focus, &:active {
                background-color: var(--color-tertiary-dark);
            }
        }
        &__border {
            background: transparent;
            border: 2px solid var(--color-accent);
            border-radius: 10px;
            color: var(--color-accent);

            &:hover,
            &:focus {
                background-color: var(--color-accent) !important;
                color: white;
            }
            &:active {
                background-color:$color-accent-dark !important;
                color: white;
            }
        }

        &__soon {
            &,
            &:hover,
            &:focus,
            &:active {
                background: var(--color-brand);
                filter: none;
                cursor: default;

                &::before {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: var(--color-brand);
                    border-radius: 0.3846em;
                    opacity: 0.7;
                }

                &::after {
                    content: 'Coming soon';
                    position: absolute;
                    inset: 0;
                    height: initial;
                    width: initial;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.5384em;
                    letter-spacing: 0.2ch;
                    text-transform: uppercase;
                    background: transparent;
                    opacity: 1;
                }
            }
        }
        &--container {
            border-radius: 0.3rem;
        }
    }
    .select-all-checkbox-btn {
        cursor:pointer;
        position: relative;
        background-color: white;
        filter: drop-shadow(0 0.5em 0.5em rgba(#000, 0.15));
        padding: 0.5rem;
        color: var(--color-text);
         &-activate {
             color: var(--color-error)
         }
    }
}
