@use 'sass:map';
@use 'sass:math';

@include animation-rotate();

.page-wrapper {
    &, *, ::before, ::after {
        box-sizing: border-box;
        outline: none;
    }

    ::selection {
        background: rgba($color-brand, 0.8);
        color: var(--color-text-light);
    }

    .container-fluid {
        width: min(#{$container-width}, #{$container-width-max});
    }

    .row {
        &__large-gutters {
            --bs-gutter-x: #{$grid-gutter-width * 2};
            //--bs-gutter-y: var(--bs-gutter-x);
        }
    }

    .txt-left {
        text-align: left;
    }

    .txt-right {
        text-align: right;
    }

    .txt-center {
        text-align: center;
    }

    .alert {
        margin: 0 0 1em;
        padding: 1em 1.5em;
        //border: 1px solid;
        border-radius: 0.5555em;
        background: var(--color-background-subtle);
        font-weight: $font-weight-semibold;
        position: fixed;
        top: 1rem;
        z-index: 100;
        right: 4rem;

        p {
            margin: 0 0 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__warning,
        &__error {
            background: rgba($color-error, 0.40);
            color: $color-error;
        }

        &__success {
            background: rgba($color-success, 0.40);
            color: $color-success;
        }

        &.v-enter-from {
            opacity: 0;
        }

        &.v-enter-active {
            transition: opacity 250ms ease-out;
        }
    }

  .status_report {
    text-transform: uppercase;
    text-align: center;
    font-size: $font-size-sm;
      &_export{
          margin:0.7rem;
          cursor: pointer;
      }
        &__green {
          color: $color-green;
        }
        &__red {
          color: $color-red;
        }
        &__grey {
          color: $color-border;
      }
  }

    .status {
        text-transform: uppercase;
        padding: 0.1222em 2em;
        text-align: center;
        border-radius: 5px;
        font-size: $font-size-sm;

        &__green {
            background-color: rgb($color-green, 0.2);
            color: $color-green;
        }
        &__red {
            background-color: rgb($color-red, 0.2);
            color: $color-red;
        }
        &__blue {
            background-color: rgb($color-blue, 0.2);
            color: $color-blue;
        }
        &__yellow {
            background-color: rgb($color-yellow, 0.2);
            color: $color-yellow;
        }
        &__orange {
            background-color: rgb($color-orange, 0.2);
            color: $color-orange;
        }
        &__grey {
            background-color: rgb($color-border, 0.2);
            color: $color-border;
        }
    }

    .popover {
        display: inline-block;
        position: relative;
    }

    .popover__menu {
        border-radius: 5px;
        border-top: 2px solid $border-color;
        background: #fff;
        margin: 8px 0;
        min-width: 150%;
        padding: 0.5rem;
        position: absolute;
        top: 100%;
        right: 0;
        box-shadow: 0 2px 3px 0 rgba(63, 63, 68, 0.2);

        transition-property: transform, opacity;
        transition-duration: 200ms;
        transition-timing-function: ease;

        transform: scale(0.9);
        transform-origin: top left;
        display: block;
        opacity: 0;

        &:after {
            bottom: 100%;
            right: 0.1rem;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: $border-color;
            //border-width: 7px;
        }
        &-item {
            white-space: nowrap;
            button {
                border: 0;
                border-radius: 0;
                width: 50%;
                text-align: center;
                &:hover,
                &:focus {
                    background-color: #f1f1f1;
                }
            }
        }
    }

    .popover--active .popover__menu {
        transform: scale(1);
        opacity: 1;
        z-index: 10;
    }

    .toggle {
        position: relative;
        box-sizing: border-box;
    }
    .toggle input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
    .toggle label {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .p {
          padding: 0 0.5rem;
            color:var(--color-text-subtle);
            margin-bottom: 0.2rem;
        }
    }
    .toggle label:before {
        content: '';
        width: 37px;
        height: 21px;
        background: #ccc;
        position: relative;
        display: inline-block;
        border-radius: 46px;
        box-sizing: border-box;
        transition: 0.2s ease-in;
    }
    .toggle label:after {
        content: '';
        position: absolute;
        width: 19px;
        height: 18px;
        border-radius: 50%;
        left: 2px;
        top: 2px;
        z-index: 2;
        background: #fff;
        box-sizing: border-box;
        transition: 0.2s ease-in;
    }
    .toggle input[type="checkbox"]:checked + label:before {
        background: #4BD865;
    }
    .toggle input[type="checkbox"]:checked + label:after {
        left: 17px;
    }
}
