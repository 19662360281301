@mixin animation-rotate {
    @keyframes animation-rotate {
        0% {
            transform: rotate(360deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
}
