$color-purple: #7284c1;
$color-purple-alt: #9272c1;
$color-blue: #9edcf9;
$color-turquoise: #6ac7bd;
$color-green: #7fc787;
$color-green-alt: #99b546;
$color-yellow: #ecd02c;
$color-orange: #f79433;
$color-red: #f04e53;
$color-pink: #f59cab;

$color-brand: $color-turquoise;
$color-brand-dark: darken($color-brand, 10%);
$color-brand-light: lighten($color-brand, 10%);
$color-accent: $color-purple;
$color-accent-dark: darken($color-accent, 25%);
$color-accent-light: lighten($color-accent, 10%);
$color-tertiary: $color-red;
$color-tertiary-dark: darken($color-red, 25%);
$color-tertiary-light: lighten($color-red, 10%);
$color-background: $color-brand;
$color-background-subtle: lighten($color-brand, 25%);
$color-background-subtle-opacity: rgba($color-brand, 0.1);
$color-background-dark: #000;
$color-background-dark-subtle: #ccc;
$color-sidenav-background: lighten($color-brand, 5%);
$color-text: #595959;
$color-text-subtle:#B2B2B2;
$color-text-light: #fff;
$color-text-light-subtle: rgba(#fff, 0.3);
$color-border: $color-text;
$color-border-subtle: #E6E6E6;
$color-account-icon: $color-orange;
$color-account-icon-background:lighten($color-orange, 30%);
$color-error: #b4042e; // #aa253d
$color-success: #05bba2; // #8fa724
