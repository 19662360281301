@use 'sass:math';
@font-face {
    font-family: LS_BlogScript;
    src: url("/fonts/LS_BlogScript.woff2") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Karla;
    src: url("/fonts/Karla-Regular.woff2") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Karla;
    src: url("/fonts/Karla-Regular.woff2") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: Karla;
    src: url("/fonts/Karla-VariableFont_wght.woff2") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Karla;
    src: url("/fonts/Karla-VariableFont_wght.woff2") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: Karla-Bold;
    src: url("/fonts/Karla-Bold.woff2") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Karla-ExtraBold;
    src: url("/fonts/Karla-ExtraBold.woff2") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Karla-Medium;
    src: url("/fonts/Karla-Medium.woff2") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 16px;
}

.page-wrapper {
    font: $font-weight-normal 1rem #{'/'} $line-height $font-family;
    font-variant-numeric: tabular-nums;
    color: $color-text;

    :is(p, h1, h2, h2, h3, h4, h5, h6) {
        font: inherit;
        margin:0;
    }

    b,
    strong {
        font-weight: $font-weight-semibold;
    }

    mark {
        color: inherit;
    }
    .p {
        margin-bottom: 0.6071em;
    }
    .h1 {
        font-family: $font-family-header;
        font-size: 3.1111em;
        line-height: 1.1428;
        font-weight: $font-weight-bold;
        color: var(--color-text);
        margin-bottom: 0.6071em;
    }

    .h2 {
        font-size: 2.1111em;
        line-height: 1.2105;
        font-weight: $font-weight-bold;
        color: var(--color-text);
        margin-bottom: 0.7391em;
    }

    .h3 {
        font-size: 1.7777em;
        line-height: 1.25;
        font-weight: $font-weight-bold;
        color: var(--color-text);
        margin-bottom: 1.0625em;
    }

    .h4 {
        font-size: 1.5555em;
        line-height: 1.3571;
        font-weight: $font-weight-semibold;
        color: var(--color-text);
        margin-bottom: 1.2142em;
    }

    .h5 {
        font-size: 0.8888em;
        line-height: 1.3571;
        font-weight: $font-weight-normal;
        color: var(--color-text);
        margin: 1rem;
    }

    .txt-large {
        font-size: 1.2222em;
        line-height: 1.5454;
        margin-bottom: 1.2727em;
    }

    .txt-small {
        font-size: 0.875em;
    }

    .txt-bold {
        font-weight: $font-weight-bold;
    }
    .txt-error {
        color: var(--color-error);
    }
    .txt-success {
        color: var(--color-success);
    }
}
