@import 'vars/colors';

:root {
    --color-red: #{$color-red};
    --color-turquoise: #{$color-turquoise};
    --color-purple: #{$color-purple};
    --color-blue: #{$color-blue};
    --color-orange: #{$color-orange};
    --color-pink: #{$color-pink};
    --color-green: #{$color-green};
    --color-yellow: #{$color-yellow};
    --color-brand: #{$color-brand};
    --color-brand-dark: #{$color-brand-dark};
    --color-brand-light: #{$color-brand-light};
    --color-accent: #{$color-accent};
    --color-accent-dark: #{$color-accent-dark};
    --color-accent-light: #{$color-accent-light};
    --color-tertiary: #{$color-tertiary};
    --color-tertiary-dark: #{$color-tertiary-dark};
    --color-tertiary-light: #{$color-tertiary-light};
    --color-sidenav-background: #{$color-sidenav-background};
    --color-background: #{$color-background};
    --color-background-subtle: #{$color-background-subtle};
    --color-background-subtle-opacity: #{$color-background-subtle-opacity};
    --color-background-dark: #{$color-background-dark};
    --color-background-dark-subtle: #{$color-background-dark-subtle};
    --color-text: #{$color-text};
    --color-text-subtle: #{$color-text-subtle};
    --color-text-light: #{$color-text-light};
    --color-text-light-subtle: #{$color-text-light-subtle};
    --color-border: #{$color-border};
    --color-border-subtle: #{$color-border-subtle};
    --color-error: #{$color-error};
    --color-success: #{$color-success};
}
