@use 'sass:math';
@use 'sass:map';

@import 'icons';

.page-wrapper {
    .form {
        position: relative;

        &--field {
            margin: 0 0 $grid-gutter-width;
            position: relative;
            background-color: white;
            display:flex;
            flex-direction: row-reverse;
            align-items: stretch;

            &--input {
                flex-grow: 1;
                font: inherit;
                line-height: initial;
                background: transparent;
                padding: 0 0 0 1em;
                border: 1px solid $color-border-subtle;
                min-height: 3rem;

                &::placeholder {
                    color: $color-text-subtle;
                    opacity: 1;
                }

                &::-webkit-calendar-picker-indicator {
                    display: none;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    appearance: none;
                    margin: 0;
                }

                &:hover,
                &:focus {
                    border-color: $color-brand !important;
                }
            }

            &--input-fix {
                display: none !important;
            }

            &--label {
                flex-basis: 11em;
                flex-grow: 0;
                line-height: initial;
                font-weight: $font-weight-normal;
                color: #1c334d;
                border-left: 1px solid $color-border-subtle;
                border-top: 1px solid $color-border-subtle;
                border-bottom: 1px solid $color-border-subtle;
                background-color: #f4f4f4;
                transition: color 150ms cubic-bezier(.215,.61,.355,1) , border-color 150ms cubic-bezier(.215,.61,.355,1);
                padding: 0.75rem 1rem;
                font-size:16px;

                ::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: #fff;
                    background-clip: padding-box;
                    border: 2px solid transparent;
                    border-radius: 3px;
                    z-index: -1;
                    transition: border-color 150ms cubic-bezier(.215,.61,.355,1);
                }
            }

            &__filled {
                .form--field--label {
                    opacity: 1;
                    transform: none;
                }
            }

            &__disabled {
                opacity: 0.3;
                cursor: default;
                pointer-events: none;
            }

            &__radio,
            &__checkbox {
                .form--field {
                        &--input {
                            position: absolute;
                            top: 7px;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            margin: 0;
                            padding: 0;
                            overflow: hidden;
                            border: none;
                            opacity: 0;
                            z-index: 2;

                            &__checked {
                                ~ .form--field--label::before {
                                    @include icon(tick);
                                    color: $color-brand;
                                }
                            }
                        }

                        &--label {
                            position: absolute;
                            top: 0;
                            left:1em;
                            display: block;
                            width: 50%;
                            margin: 0 0 0 calc(1em + 1ch);
                            padding: 0;
                            font: inherit;
                            font-weight: $font-weight-normal;
                            text-transform: inherit;
                            opacity: 1;
                            transform: none;
                            background-color: transparent;
                            border: none !important;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0.4em;
                                left: -2em;
                                height: 1em;
                                width: 1em;
                                line-height: 1em;
                                border: 1px solid $color-border;
                                background-clip: content-box;
                                transition: background-color 500ms $ease-out-quint, border-color 500ms $ease-out-quint;
                            }

                            &__caption {
                                display: block;
                                font-weight: $font-weight-normal;
                                font-style: italic;
                            }

                            &:hover,
                            &:focus {
                                &::before {
                                    border-color: $color-brand-dark;
                                }
                            }
                        }
                }
            }

            &__radio {
                .form--field--label::before {
                    top: 0;
                    border-radius: 100%;
                }
            }

            &__checkbox {
                + .form--field__checkbox {
                    margin-top: -1.5em;
                }
            }

            &__select,
            &__postcode {
                .form--field {
                    &--input {
                        padding-right: 2.5em;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        cursor: pointer;

                        &__multiple {
                            min-height: 3.3571em;
                            padding-top: 0.7143em;
                            display: inline-block;
                            align-items: center;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            gap: 0.2857em;

                            .form--field--input--option {
                                display: inline-block;
                                width: auto;
                                padding: 0.1428em 0.2857em 0 0.5em;
                                background: #fafafa;
                                margin: 0.1em;

                                &::after {
                                    @include icon(close);

                                    position: relative;
                                    top: -0.1666em;
                                    margin: 0 0 0 0.5em;
                                    font-size: 0.8571em;
                                }
                            }
                        }
                    }

                    &--options {
                        display: none;
                        position: absolute;
                        top: calc(100% - 1px);
                        z-index: 150;
                        width: 100%;
                        padding: 0 0.8888em;
                        background: #fafafa;
                        border: 1px solid var(--color-border-subtle);
                        border-top-width: 0;
                        max-height: 13em !important;
                        overflow: auto !important;

                        &--option {
                            padding: 0.4444em 0 0.2222em;
                            cursor: pointer;

                            &__selected {
                                font-weight: $font-weight-semibold;
                            }

                            &__empty {
                                font-style: italic;
                                color: var(--color-text-subtle);
                            }

                            &:first-child {
                                border-top: 1px solid var(--color-border-subtle);
                            }

                            &:last-child {
                                padding-bottom: 0.4444em;
                            }
                        }
                    }

                    &--input__open {
                        border-color: var(--color-border) !important;

                        ~ .form--field--options {
                            display: block;
                        }
                    }
                }

                &::after {
                    @include icon(chevron-down);

                    position: absolute;
                    bottom: 0.8888em;
                    right: 1em;
                    color: var(--color-text-subtle);
                }
            }

            &__postcode {
                .form--field {
                    &--options {
                        &--option {
                            &:last-child:nth-child(n+2) {
                                margin-top: 0.2222em;
                                border-top: 1px solid $color-border-subtle;
                                font-weight: $font-weight-semibold;
                            }
                        }
                    }
                }
            }

            &__date {
                .form--field {
                    &--datepicker {
                        display: none;
                        position: absolute;
                        top: calc(100% - 1px);
                        left: 0;
                        right: 0;
                        z-index: 120;
                        padding: 0 0.8888em;
                        background: white;
                        border: 1px solid var(--color-border);
                        border-top-width: 0;

                        &--nav {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            &--select {
                                margin: 0;
                                width: 8ch;

                                &::after {
                                    bottom: 2em;
                                    font-size: 0.5em;
                                }

                                .form--field {
                                    &--input {
                                        border-color: transparent;
                                        padding: 0.25em 0.5em;
                                        font-weight: $font-weight-bold;

                                        &:hover,
                                        &__open {
                                            border-color: var(--color-text);
                                        }
                                    }

                                    &--options {
                                        padding: 0 0.5em;
                                        width: 100%;

                                        &--option {
                                            padding: 0.125em 0;
                                        }
                                    }
                                }

                                &__month {
                                    margin-left: auto;
                                    text-align: right;

                                    &::after {
                                        left: 1em;
                                        right: auto;
                                    }

                                    .form--field--input {
                                        padding-left: 1em;
                                        text-align: right;
                                    }
                                }

                                &__year {
                                    margin-right: auto;
                                    margin-left: -1px;

                                    &::after {
                                        right: 1em;
                                    }

                                    .form--field--input {
                                        padding-right: 1em;
                                    }
                                }
                            }

                            &--link {
                                color: inherit;

                                &:is(:hover, :focus) {
                                    color: var(--color-brand);
                                }
                            }
                        }

                        &--dates {
                            margin: 1em 0;
                            display: grid;
                            grid-template-columns: repeat(7, 1fr);
                            gap: 0.5em;
                            text-align: center;

                            &--date {
                                display: block;
                                padding: 0.125em;
                                border-radius: 0.1875em;
                                color: inherit;
                                font-weight: inherit;
                                text-decoration: none;

                                @for $i from 1 through 6 {
                                    &__offset-#{$i} {
                                        grid-column: #{$i + 1};
                                    }
                                }

                                &:is(:hover, :focus) {
                                    color: var(--color-brand);
                                }

                                &__today {
                                    font-weight: $font-weight-bold;
                                }

                                &__disabled {
                                    opacity: 0.5;
                                    color: var(--color-text-subtle) !important;
                                    cursor: not-allowed;
                                }

                                &__selected {
                                    background: var(--color-background-subtle);
                                    font-weight: $font-weight-semibold;
                                }
                            }
                        }
                    }

                    &--input__open {
                        border-color: var(--color-text) !important;

                        ~ .form--field--datepicker {
                            display: block;
                        }
                    }
                }

                &::after {
                    @include icon(calendar);

                    position: absolute;
                    bottom: 0.8888em;
                    right: 1em;
                    color: var(--color-text-subtle);
                }
            }

            &__autocomplete {
                position: relative;
                //width: 130px;
            }

            &__autocomplete-results {
                position: absolute;
                top: 2.3rem;
                padding: 0.5rem;
                margin: 0;
                border: 1px solid #eeeeee;
                height: 15rem;
                overflow: auto;
                width: 100%;
                background: white;
                z-index:200;
            }

            &__autocomplete-result {
                list-style: none;
                text-align: left;
                padding: 4px 2px;
                cursor: pointer;
            }
            ul li::marker {
                content: "";
                color: transparent;
            }

            &__autocomplete-result.is-active,
            &__autocomplete-result:hover {
                background-color: var(--color-brand);
                color: white;
            }

            &__error {
                .form--field {
                    &--input {
                        border-color: var(--color-error) !important;
                    }
                }
                .form--field {
                    &--label {
                        color: var(--color-error) !important;
                    }
                }
            }

            &__rich-text {
                display: flex;
                flex-direction: column;

                > .form--field--label {
                    order: 0;
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 1;
                    margin: 0;
                    padding: 0.8571em 1.2857em 0.7857em;
                    border: 1px solid $color-border-subtle;
                    border-bottom-width: 0;
                    background: white;
                    transition: border-color 500ms $ease-out-quint;
                }

                .form--field {
                    &--toolbar {
                        order: 1;
                        height: calc(#{$line-height}em + 1.6428em);
                        width: 100%;
                        padding: 0 1.2857em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: $grid-gutter-width / 2;
                        border: 1px solid $color-border-subtle;
                        background: whitesmoke;
                        transition: border-color 500ms $ease-out-quint;

                        &--btn {
                            padding: 0.2857em 0.3571em;
                            border: none;
                            border-radius: 0.2142em;
                            background: transparent;
                            color: inherit;
                            transition: background 150ms ease-out, color 150ms ease-out;

                            &::before {
                                @include icon();
                            }

                            &__bold::before {
                                content: map.get($icons, editor-bold);
                            }

                            &__italic::before {
                                content: map.get($icons, editor-italic);
                            }

                            &__underline::before {
                                content: map.get($icons, editor-underline);
                            }

                            &__bullet-list::before {
                                content: map.get($icons, editor-bullet-list);
                            }

                            &__ordered-list::before {
                                content: map.get($icons, editor-ordered-list);
                            }

                            &__link::before {
                                content: map.get($icons, editor-link);
                            }

                            &__image::before {
                                content: map.get($icons, editor-image);
                            }

                            &:hover,
                            &:focus,
                            &__active {
                                background: $color-background-subtle;
                                color: $color-brand;
                            }
                        }

                        &--select {
                            margin: 0 calc(-1px - 0.7142em);
                            width: 16ch;

                            &::after {
                                bottom: 0.8571em;
                                right: 0.7142em;
                            }

                            .form--field {
                                &--input {
                                    padding: 0.5em 0.7142em 0.4285em;
                                    position: relative;

                                    &::placeholder {
                                        color: inherit;
                                    }

                                    &:not(:hover, :focus, .form--field--input__open) {
                                        border-color: transparent;
                                    }
                                }

                                &--options {
                                    left: 0;
                                    padding: 0 0.7142em;

                                    &--option {
                                        padding: 0.5em 0 0;

                                        &:last-child {
                                            padding-bottom: 0.4285em;
                                        }
                                    }
                                }
                            }
                        }

                        &--break {
                            display: block;
                            height: 1em * $line-height;
                            margin: 0 $grid-gutter-width / 2;
                            border-right: 1px solid $color-border-subtle;
                        }
                    }

                    &--editor {
                        order: 2;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;
                        border: 1px solid $color-border-subtle;
                        border-top-width: 0;
                        background: white;
                        transition: border-color 500ms $ease-out-quint;

                        .ProseMirror {
                            box-sizing: content-box;
                            min-height: $line-height * 10em;
                            overflow: visible;
                            //padding: calc(0.8571em - 1px) max(1.2857em, calc(50% - (min(col-width(9), col-width-max(9)) / 2))) calc(0.7857em - 1px);
                            padding: 1rem;

                            p {
                                margin: 0 0 1em;
                            }

                            h1 {
                                font-weight: $font-weight-bold;
                                font-size: 2.8571em;
                                line-height: 1.05;
                                text-transform: uppercase;
                                margin: 0.7em 0 0.35em;
                            }

                            h2 {
                                font-weight: $font-weight-bold;
                                font-size: 1.7142em;
                                line-height: 1.25;
                                margin: 1.1666em 0 0.5833em
                            }

                            h3 {
                                font-weight: $font-weight-semibold;
                                font-size: 1.4285em;
                                line-height: 1.3;
                                margin: 1.4em 0 0.7em
                            }

                            h6 {
                                font-size: 1.2857em;
                                line-height: 1.4444;
                                margin: 0 0 0.7777em;
                            }

                            :is(h1, h2, h3):first-child {
                                margin-top: 0;
                            }

                            a {
                                text-decoration: underline rgba($color-brand, 0);
                                color: $color-brand;

                                &:hover,
                                &:focus {
                                    text-decoration-color: $color-brand;
                                }
                            }

                            ul, ol {
                                margin: 0 0 1em;
                                padding: 0 0 0 2.5em;
                                overflow: auto;

                                li {
                                    margin: 0 0 0.5em;
                                    padding: 0 0 0 0.5em;

                                    &::before {
                                        display: none;
                                    }

                                    &::marker {
                                        //font-weight: $font-weight-semibold;
                                        color: mix($color-text-subtle, $color-text, 75%);
                                    }
                                }
                            }

                            ul li {
                                list-style: disc outside;

                                &::marker {
                                    font-size: 1.2857em;
                                    line-height: 1;
                                }
                            }

                            ol li {
                                list-style: decimal outside;
                            }

                            > :last-child {
                                margin-bottom: 0;
                            }
                        }

                        &:hover,
                        &:focus,
                        &__focussed {
                            border-color: $color-border;

                            ~ .form--field--toolbar {
                                border-left-color: $color-border;
                                border-right-color: $color-border;
                            }

                            ~ .form--field--label {
                                border-color: $color-border;
                            }
                        }

                        &__no-label {
                            &.form--field--editor__simple {
                                border-top-width: 1px;
                            }

                            &:hover,
                            &:focus,
                            &.form--field--editor__focussed {
                                ~ .form--field--toolbar {
                                    border-top-color: $color-border;
                                }
                            }
                        }
                    }
                }
            }
            &__files {
                display:unset;
            }
            &--file {
                position: relative;
                &-input {
                    opacity: 0;
                    overflow: hidden;
                    width:100%;
                    cursor: pointer;
                }
                &-label {
                    cursor: pointer;
                    height: inherit;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 1em;
                    padding: 0.5rem;

                    span .underline {
                        text-decoration: underline;
                    }
                    button {
                        cursor:pointer;
                        float: right;
                        padding: 0.6rem
                    }
                }
            }
        }

        &--field-group {
            margin: 0 0 $grid-gutter-width;
            padding: 0;
            border: none;
            display: flex;
            align-items: flex-start;
            gap: $grid-gutter-width;

            .form--field {
                margin: 0;
                flex-grow: 1;
            }

            .btn {
                padding-left: 0.8461em;
                padding-right: 0.8461em;

                .icon,
                [class*='icon__'] {
                    margin: 0;
                }
            }

            &__tight {
                gap: 0;

                .form--field {
                    &:nth-child(n+2) {
                        .form--field--input {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-left-width: 0;
                        }
                    }

                    &:nth-last-child(n+2) {
                        .form--field--input {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border-right-width: 0;
                        }
                    }
                }

                .btn {
                    &:nth-child(n+2) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    &:nth-last-child(n+2) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }

        &--multiline-field-group {
            border: none;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: $grid-gutter-width;
            padding: 0;

            > .form--field,
            > .form--field-group,
            > .btn {
                margin: 0;
            }

            &__tight {
                gap: 0;

                > .form--field:nth-child(n+2),
                > .form--field-group:nth-child(n+2),
                > .btn:nth-child(n+2) {
                    margin-top: -1px;
                }

                > .form--field:nth-child(n+2) .form--field--input,
                > .form--field-group:nth-child(n+2) .form--field--input,
                > .form--field-group:nth-child(n+2) .btn,
                > .btn:nth-child(n+2) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }

                > .form--field:nth-last-child(n+2) .form--field--input,
                > .form--field-group:nth-last-child(n+2) .form--field--input,
                > .form--field-group:nth-last-child(n+2) .btn,
                > .btn:nth-last-child(n+2) {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &--buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: $grid-gutter-width;
            margin-top: 2em;

            .form--button__back + .form--button:not(.form--button__back) {
                margin-left: auto;
            }
        }

        &--confirmation {
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 100%;
            overflow: hidden;
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > :last-child {
                margin-bottom: 0;
            }
        }

        .alert {
            margin: 0 0 1em;
        }

        &__sent {
            .form--confirmation {
                height: 100%;
                opacity: 1;
                transition: opacity 500ms $ease-out-quint;
            }

            > :not(.form--confirmation) {
                opacity: 0.3 !important;
                filter: blur(0.5rem) !important;
                transition: opacity 500ms $ease-out-quint, filter 500ms $ease-out-quint !important;
            }
        }

        &__large {
            .form--field--input {
                padding: 1em 1.5em;
            }

            .btn {
                padding: 1em 1.5em;
            }
        }
    }

    .StripeElement {
        border-color: var(--color-border);

        &--empty:not(.StripeElement--focus) {
            border-color: var(--color-border-subtle);
        }

        &--invalid {
            border-color: var(--color-error);
        }
    }
}

.grecaptcha-badge {
    display: none;
}
