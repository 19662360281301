@use 'sass:map';

.page-wrapper {
    ul, ol {
        margin: 0 0 0.7777em;
        padding: 0;
        position: relative;

        li {
            padding: 0 0 0 1ch;
            margin: 0 0 0.7777em 1ch;
        }
    }

    ul {
        li {
            &::marker {
                content: '■';
                color: var(--color-brand);
            }
        }
    }

    ol {
        counter-reset: list;

        li {
            counter-increment: list;

            &::marker {
                content: counter(list);
                color: var(--color-brand);
                font-weight: $font-weight-semibold;
            }
        }
    }

    .list {
        &__footnotes {
            font-size: 0.6666em;

            li {
                &::marker {
                    color: inherit;
                    font-weight: inherit;
                }
            }
        }
    }
}
