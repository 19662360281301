$font-family: Karla;
$font-family-header: $font-family;
$font-family-brand: $font-family;

$font-family-bold: Karla-Bold;
$font-family-header-bold: $font-family-bold;
$font-family-brand-bold: $font-family-bold;

$font-family-extra-bold: Karla-ExtraBold;
$font-family-header-bold: $font-family-extra-bold;
$font-family-brand-bold: $font-family-extra-bold;

$font-family-medium: Karla-Medium;
$font-family-header-bold: $font-family-medium;
$font-family-brand-bold: $font-family-medium;


$font-script: LS_Blogscript;
$font-script-header: $font-script;
$font-script-brand: $font-script;


$font-weight-bold: 900;
$font-weight-semibold: 600;
$font-weight-normal: 400;

$line-height: 1.8888;
$line-height-uppercase: 1.125;

