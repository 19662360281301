@use 'sass:map';

@import '~normalize.css/normalize';
@import './src/vars';
@import '~bootstrap/scss/bootstrap-grid';
@import './src/colors';
@import './src/typography';
@import './src/icons';
@import './src/links';
@import './src/buttons';
@import './src/forms';
@import './src/tables';
@import './src/lists';
@import './src/misc';


body {
    overflow: hidden;
}

.page-wrapper {
    font-family: $font-family;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    //grid-template-rows: 1fr 20px;
    background: local;
    color: $color-text;
    /*    font-size: 0.85em;
        font-weight: normal;*/

    table .icon {
        color: $color-brand;
    }

    [v-cloak] > * {
        display: none;
    }

    [v-cloak]::before {
        content: " ";
        display: block;
        position: absolute;
        width: 80px;
        height: 80px;
        //background-image: url(/images/loading-white.svg);
        background-size: cover;
        left: 50%;
        top: 50%;
    }

    .click-through {
        pointer-events: none;
    }

    .hide-element {
        opacity: 0;
        pointer-events: none;
    }

    .modal-loader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, .5);
        animation: fadein .5s;
        z-index: 9999;
        height: 100vh;

        &--spinner {
            left: 50%;
            position: absolute;
            text-align: center;
            top: 50%;
        }
    }

    .modal {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(77, 77, 77, .5);
        animation: fadein .5s;
        z-index: 9999;
        height: 100vh;
        overflow-y:auto;

        &--spinner {
            left: 50%;
            position: absolute;
            text-align: center;
            top: 50%;
        }
    }

    .modal__content {
        border-radius: 15px;
        position: relative;
        top: 50px;
        width: 500px;
        max-width: 90%;
        background: #fff;
        padding: 1em 2em;
    }

    .modal__inner {
        height: 100%;
    }

    .modal__footer {
        text-align: right;

        a {
            color: #585858;
        }

        i {
            color: #d02d2c;
        }
    }

    .modal__close {
        position: absolute;
        top: -17px;
        right: -9px;
        color: #585858;
        text-decoration: none;
        background: white;
        border-radius: 50px;
        width: 2rem;
        height: 2rem;
        text-align: center;
    }

    .hr {
        height: 2px;
        width: 100%;
        background-color: rgb($color-brand-dark, 0.5);
        border: none;
        margin: 1rem 0;
    }

    .main-body {
        width: 100vw;
        display: flex;
        transition: 1s ease;
        overflow: hidden;
        position: relative;
        background-color: $color-background-subtle-opacity;

        &--sidebar-small {
            width: 7% !important;
        }

        &--sidebar {
            background-color: $color-sidenav-background;
            width: 20%;
            height: 100vh;
            padding: 1.5rem;
            color: $color-text;
            transition: 1s ease;
            position: relative;

            button {
                position: absolute;
                background-color: inherit;
                border: none;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                left: 100%;
                top: 2rem;
                transform: translateX(-50%);
                cursor: pointer;
                color: white
            }

            &--logo {
                background-color: $color-sidenav-background;
                background-image: url("/images/logo-menu.svg");
                background-repeat: no-repeat;
                background-position-x: center;
                background-size: 85%;
                margin-top: 1rem;
                padding: 2rem;
            }

            &--logo-small {
                background-color: $color-sidenav-background;
                background-image: url("/images/logo-mini.svg");
                width: fit-content;
                margin: 0 auto;
            }

            &--menu-content {
                overflow-y: scroll;
                height: 80%;

                &--collapsed {
                    opacity:0;
                }

                &--main-box {
                    background-color: $color-background-subtle;
                    border-radius: 10px;
                    margin-bottom: 1rem;
                    font-weight: normal;

                    &-ul {
                        //text-align: left;
                        //list-style-type: none;
                        //width: 100%;
                    }

                    &-li {
                        width: 100%;

                        &-accordion {
                            -webkit-transition: all 0.25s ease !important;
                            -o-transition: all 0.25s ease !important;
                            transition: all 0.25s ease !important;
                        }

                        > div {
                            border-bottom: 1px solid $color-background;
                            color: $color-text;
                            width: 100%;
                        }

                        > div:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        &-active > div {
                            text-decoration: none;
                        }

                        &-text {
                            padding: 10px;
                            font-weight: $font-weight-normal;

                            &_heading {
                                border-bottom: 3px solid $color-background !important;
                                font-weight: $font-weight-semibold;
                            }

                            i {
                                float: right;
                                color: $color-brand;
                                font-size: large;
                            }
                        }
                    }
                }
            }

            &--footer {

                &-icon {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $color-account-icon-background;
                    color: $color-account-icon;
                }

                a {
                    font-weight: 400;
                    text-decoration: underline transparent;
                    color: #fff;
                    font-size: small;
                    margin-left: 0.5em;
                }
            }

            &--hidden {
                opacity: 0;
                width:10% !important;
            }
        }

        &--breadcrumb-header {
            height: 4.5rem;

            &--breadcrumbs {
                //margin: 0 4rem;

                &--notifications {
                    float: right;
                    font-size: 1.4em
                }
            }
        }

        &--main-content-large {
            width: 90% !important;
        }

        &--main-content {
            width: 80%;
            height: 100vh;
            overflow-y: scroll;
            padding: 2rem;
            transition: 1s ease;
            margin: 0 2rem;

            &--container {
                background-color: white;
                padding: 1rem;
                margin-bottom: 1rem;

                hr {
                    height: 2px;
                    width: 100%;
                    background-color: $color-brand-dark;
                    border: none;
                    margin-bottom: 1rem;
                }

                .icon {
                    color: $color-brand;
                    cursor: pointer;
                }
            }

            &--images {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1em;

                .icon,
                [class*='icon__'] {
                    margin: 0;
                    font-size: 2.5em;
                }

                &-cards {
                    &--card {
                        border-radius: 10px;
                        position: relative;

                        &-holder {
                            width: 250px;
                            min-height: 250px;
                            padding: 0;
                            margin: 0 auto;
                        }

                        img {
                            max-width: 100%;
                            border-radius: 10px;
                        }
                    }
                }

                &-multi-cards {
                    &--card {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                        grid-row-gap: 20px;
                        grid-column-gap: 3rem;
                        justify-content: center;
                        width: 100%;
                        margin: 0 auto;
                        list-style: none;
                        min-height: 150px;
                        background: transparent !important;
                        text-align: center;
                        img {
                            max-width: 100%;
                            border-radius: 10px;
                        }
                    }
                }

                &-delete_button {
                    width: 1.3em;
                    height: 1.3em;
                    color: var(--color-error);
                    font-weight: bold;
                    cursor: pointer;
                    position: absolute;
                    z-index: 20;
                    top: 1px;
                    right: 2px;
                }
                &_toggle {
                    position: relative;
                    float:right;

                    input[type='checkbox'] {
                        height: 0;
                        width: 0;
                        visibility: hidden;
                    }

                    label {
                        cursor: pointer;
                        text-indent: -9999px;
                        width: 60px;
                        height: 30px;
                        background: grey;
                        display: block;
                        border-radius: 100px;
                        position: relative;
                    }

                    label::after {
                        content: '';
                        position: absolute;
                        top: 3px;
                        left: 5px;
                        width: 25px;
                        height: 25px;
                        background: #fff;
                        border-radius: 90px;
                        transition: 0.3s;
                    }

                    input:checked + label {
                        background: #bada55;
                    }

                    input:checked + label::after {
                        left: calc(100% - 5px);
                        transform: translateX(-100%);
                    }

                    label:active::after {
                        width: 130px;
                    }
                }
            }

            &__video-container {
                position: relative;

                &-video {
                    width: 100%;
                }

                &-playpause {
                    background-image: url(/images/video-play-btn.svg);
                    background-repeat: no-repeat;
                    width: 20%;
                    height: 20%;
                    position: absolute;
                    top: 0%;
                    bottom: 0%;
                    right: 39%;
                    margin: auto;
                    background-size: contain;
                    background-position: center;
                }
            }

            &-upload-button {
                &_box {
                    text-align: center;

                    &-file_input {
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        pointer-events: all;
                    }

                    &-label {
                        display: inline-block;
                        position: relative;
                        padding: 0.4444em 1.2222em;
                        background: transparent;
                        border: 2px solid var(--color-accent);
                        border-radius: 10px;
                        color: var(--color-accent);
                        line-height: $line-height;
                        font-weight: $font-weight-semibold;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }

            &--address-box {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                z-index: 1;
                margin-bottom: 1rem;

                &-label {
                    flex-basis: 11em;
                    flex-grow: 0;
                    line-height: initial;
                    font-weight: $font-weight-normal;
                    color: #1c334d;
                    border-left: 1px solid $color-border-subtle;
                    border-top: 1px solid $color-border-subtle;
                    border-bottom: 1px solid $color-border-subtle;
                    background-color: #f4f4f4;
                    transition: color 150ms cubic-bezier(.215, .61, .355, 1), border-color 150ms cubic-bezier(.215, .61, .355, 1);
                    padding: 0.75rem 1rem;
                }

                &-content {
                    flex-grow: 1;
                    font: inherit;
                    line-height: initial;
                    background: transparent;
                    border: 1px solid $color-border-subtle;
                    padding: 1rem;

                    &--edit {
                        border: 1px solid $color-border;
                    }
                }
            }

            &--dynamic-values {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 1rem;

                &-type {
                    //border: 1px solid $color-border;
                    padding: 0.5em;

                    .form--field {
                        border: none !important;
                        height: 100%;

                        &__select::after {
                            bottom: 0;
                        }
                    }

                    &-saved {
                        border-bottom: 1px solid $color-border-subtle !important;
                    }

                    &-title {
                        border: none;
                        text-transform: uppercase;
                        color: rgb($color-text, 0.5);
                    }
                }

                &-value {
                    padding: 0.5em;
                    //border: 1px solid $color-border;
                    display: grid;
                    grid-template-columns: 1fr 0.4fr;

                    .form--field {
                        border: none !important;
                        height: 100%;
                    }

                    &-saved {
                        border-bottom: 1px solid $color-border-subtle !important;
                    }

                    &-title {
                        border: none;
                        text-transform: uppercase;
                        color: rgb($color-text, 0.5);
                    }
                }
            }

            &--pricing-values {
                display: grid;
                grid-template-columns: 1fr 0.5fr;
                margin-bottom: 1rem;

                &-type {
                    //border: 1px solid $color-border;
                    padding: 0.5em;

                    .form--field {
                        border: none !important;

                        &__input, &__select {
                            width: 80%
                        }

                        &__select::after {
                            bottom: 0;
                        }
                    }

                    &-saved {
                        border-bottom: 1px solid $color-border-subtle !important;
                    }

                    &-title {
                        border: none;
                        text-transform: uppercase;
                        color: rgb($color-text, 0.5);
                    }
                }

                &-value {
                    padding: 0.5em;
                    //border: 1px solid $color-border;
                    display: grid;
                    grid-template-columns: 1fr 0.4fr;

                    .form--field {
                        border: none !important;
                        /*height: 100%;*/
                    }

                    &-saved {
                        border-bottom: 1px solid $color-border-subtle !important;
                    }

                    &-title {
                        border: none;
                        text-transform: uppercase;
                        color: rgb($color-text, 0.5);
                    }
                }
            }

            /* Accordion styles */
            &--accordion {
                margin-top: 2rem;
                color: $color-text;

                .accordion__header {
                    padding: 1em;
                    border-top: 2px solid $color-background;
                    margin-top: 2px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    vertical-align: center;
                    cursor: pointer;

                    &_registration {
                        border-image: url("/images/registration-accordion-top-border.svg") 100% 0 0 0 / 0.35em 0 0 0 repeat;
                        color: white;
                    }
                }

                .accordion__header > * {
                    //margin-top: 1.5rem;
                    font-size: 1.3rem;
                }

                .accordion__header.is-active {
                    //background-color: #000;
                }

                .accordion__registration_item_number {
                    font-family: $font-script;
                    margin: 1.5rem 1.5rem 1.5rem 0;
                    height: 3rem;
                    background-color: $color-brand-light;
                    width: 2.5rem;
                    border-radius: 50%;
                    padding: 0.8rem;
                    font-size: xx-large;
                    line-height: 1.5;
                }

                .accordion__toggle {
                    margin: 1.5rem 1.5rem 1.5rem 0;
                    height: 1.5rem;
                    background-color: $color-brand-light;
                    width: 1.5rem;
                    display: block;
                    position: relative;
                    border-radius: 50%;
                }

                .accordion__toggle::before {
                    content: "\2304";
                    width: 1.5rem;
                    height: 1.5rem;
                    display: block;
                    /* background-image: url(/image/accordion-circle.svg); */
                    /* background-color: #222; */
                    position: absolute;
                    top: -1.6rem;
                    left: 0.1rem;
                    font-size: 2rem;
                    border-radius: 50%;
                    color: white;
                }

                .is-active .accordion__toggle {
                    background-color: $color-brand-light;
                }

                .is-active .accordion__toggle::before {
                    transform: rotate(180deg);
                    left: -0.2rem;
                    top: 1.7rem;
                }

                .accordion__body {
                    display: none;
                    padding: 1em;
                    //border: 1px solid #ccc;
                    border-top: 0;
                    //margin-top: 2rem;
                }

                .accordion__body.is-active {
                    display: block;
                }
            }

            &-map-container {
                position: relative;
                height: 97%;

                &--map {
                    position: absolute;
                    inset: 0 0 0;
                    text-align: left;

                    &--map {
                        position: absolute;
                        inset: 0;
                    }
                }
            }

            &--table-filter {
                position: relative;
                background-color: white;
                border: 1px solid $color-border-subtle;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                margin: 1rem 0;
                overflow-x:auto;


                /* &-box {
                     position: absolute;
                     background-color: white;
                     z-index: 5;
                     top: 2.5rem;
                     left: 0;
                     width:100%;
                     padding: 1.5rem;
                     display: grid;
                     grid-template-columns: repeat(2, 1fr);
                     margin-bottom: 1rem;
                     grid-gap: 1rem;
                     grid-template-rows: 1fr;
                     border-left: 1px solid $color-border-subtle;
                     border-right: 1px solid $color-border-subtle;
                     border-bottom: 1px solid $color-border-subtle;
                     border-bottom-left-radius: 10px;
                     border-bottom-right-radius: 10px;
                     -moz-border-radius-bottomleft: 10px;
                     -moz-border-radius-bottomright: 10px;
                     box-shadow: 0px 5px 5px $color-border-subtle;
                 }*/
                &-box {
                    position: relative;
                    top: 1rem;
                    left: 0;
                    width: 100%;
                    display: grid;
                    //grid-template-columns: repeat(2, 1fr);
                    grid-template-columns:repeat(auto-fit,minmax(400px, 1fr));
                    margin-bottom: 1rem;
                    grid-gap: 1rem;
                    grid-template-rows: 1fr;
                }
            }

            &--table-filter.hide {
                background-color: transparent;
                border: 0;
            }

            &--table-filter-box.hide {
                display: none;
            }

            &--table-filter-admin {
                display: grid;
                grid-template-columns: 1fr 0.6fr 1fr;
                margin-bottom: 1rem;
                grid-gap: 1rem;
                grid-template-rows: 1fr;
            }

            &--contact_details {
                position: relative;
                background-color: white;
                border: 1px solid $color-border-subtle;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                margin: 1rem 0;

                /* &-box {
                     position: absolute;
                     background-color: white;
                     z-index: 5;
                     top: 2.5rem;
                     left: 0;
                     width:100%;
                     padding: 1.5rem;
                     display: grid;
                     grid-template-columns: repeat(2, 1fr);
                     margin-bottom: 1rem;
                     grid-gap: 1rem;
                     grid-template-rows: 1fr;
                     border-left: 1px solid $color-border-subtle;
                     border-right: 1px solid $color-border-subtle;
                     border-bottom: 1px solid $color-border-subtle;
                     border-bottom-left-radius: 10px;
                     border-bottom-right-radius: 10px;
                     -moz-border-radius-bottomleft: 10px;
                     -moz-border-radius-bottomright: 10px;
                     box-shadow: 0px 5px 5px $color-border-subtle;
                 }*/
                &-box {
                    position: relative;
                    top: 1rem;
                    left: 0;
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    //grid-template-columns:repeat(auto-fit,minmax(300px, 1fr));
                    margin-bottom: 1rem;
                    grid-gap: 1rem;
                    grid-template-rows: 1fr;
                    &-small {
                        grid-template-columns:repeat(auto-fit,minmax(300px, 1fr));
                    }
                }

            }

            &--enquiry-details {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 1rem;

                &--grid {
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-gap: 0.5rem;
                    grid-template-rows: 1fr;
                    padding: 1rem;

                    p {
                        margin: 0;
                        font-size: smaller;
                    }

                    &--line:not(:last-child) {
                        grid-column: 1 / -1;
                        height: 2px;
                        border-bottom: 1px solid $color-border-subtle;
                        width: 100%;
                    }

                    &--line + div {
                        grid-column: 1;
                    }
                }

                &--conversation {
                    width: 75%;

                    &--name-date {
                        text-transform: uppercase;
                        color: var(--color-text-subtle);
                        font-size: $font-size-sm;
                    }

                    &_incoming {
                        margin-left: 7rem;

                        &-message {
                            padding: 1rem;
                            background-color: $color-sidenav-background;
                            border-radius: 5px;
                            color: white;
                        }
                    }

                    &_outgoing {
                        position: relative;

                        &-message {
                            padding: 1rem;
                            background-color: $color-purple;
                            border-radius: 5px;
                            color: white;
                        }

                        .form--field--input {
                            border-radius: 10px;
                        }

                        .btn {
                            position: absolute;
                            top: 0.5rem;
                            right: 0.4444rem;
                            filter: none;
                            padding: 0.4444em;
                            border-radius: 0.3rem;
                        }
                    }
                }

            }

            &--customer-details--grid {
                display: grid;
                grid-template-columns: 1fr 1fr 0.5fr 0.4fr;
                grid-gap: 0.5rem;
                grid-template-rows: 1fr;
                //padding: 1rem;

                &-redemption {
                    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr !important;
                }

                p {
                    margin: 0;
                    font-size: smaller;
                }

                &-title {
                    border: none;
                    text-transform: uppercase;
                    color: rgb($color-text, 0.5);
                }

                &--body {
                    padding: 1rem;
                }

                &--line:not(:last-child) {
                    grid-column: 1 / -1;
                    height: 2px;
                    border-bottom: 1px solid $color-border-subtle;
                    width: 100%;
                }

                &--line + div {
                    grid-column: 1;
                }
            }

            &--user-permissions--grid {
                display: grid;
                grid-template-columns: 2fr 0.5fr;
                grid-gap: 0.5rem;
                grid-template-rows: 1fr;
                padding: 1rem;

                p {
                    margin: 0;
                    font-size: smaller;
                }

                &--line:not(:last-child) {
                    grid-column: 1 / -1;
                    height: 2px;
                    border-bottom: 1px solid $color-border-subtle;
                    width: 100%;
                }

                &--line + div {
                    grid-column: 1;
                }
            }

            &--user-notifications--grid {
                display: grid;
                grid-template-columns: 2fr 0.5fr 0.5fr;
                grid-gap: 0.5rem;
                grid-auto-rows: min-content;
                padding: 0 1rem;

                p {
                    margin: 0;
                    font-size: smaller;
                }

                &--header {
                    text-align: center;
                    text-transform: uppercase;
                    height: 25px;
                    font-size: 12px;
                    color: rgb($color-text, 0.4);
                }

                &--line:not(:last-child) {
                    grid-column: 1 / -1;
                    height: 2px;
                    border-bottom: 1px solid $color-border-subtle;
                    width: 100%;
                }

                &--line + div {
                    grid-column: 1;
                }
            }

            &--user-permissions-tick {
                color: $color-brand;
                cursor: pointer;
            }

            &--user-permissions-cross {
                color: rgb($color-text, 0.4);
                cursor: pointer;
            }

            &--order-details {
                &--table {
                    &--not-selected {
                        border-left: 1px solid white;
                        border-right: 1px solid white;
                    }

                    &--selected {
                        border-left: 1px solid $color-border-subtle;
                        border-right: 1px solid $color-border-subtle;

                        input, textarea {
                            border: unset;
                            color: inherit;
                            padding: 0;
                            width: 95%;
                        }
                    }
                }
                &--status_select{
                    border: 1px solid lightgray;
                    padding: 0.5rem 1rem;
                }

            }

            &--card_redemptions--grid {
                display: grid;
                grid-template-columns: 1fr 1fr 0.5fr;
                grid-gap: 0.5rem;
                grid-auto-rows: min-content;
                padding: 0 1rem;

                p {
                    margin: 0;
                    font-size: smaller;
                }

                &--header {
                    text-transform: uppercase;
                    height: 25px;
                    font-size: 10px;
                    color: rgb($color-text, 0.4);
                }

                &--line:not(:last-child) {
                    grid-column: 1 / -1;
                    height: 2px;
                    border-bottom: 1px solid $color-border-subtle;
                    width: 100%;
                }

                &--line + div {
                    grid-column: 1;
                }
            }

            &--card_prefixes--grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.5rem;
                grid-auto-rows: min-content;
                padding: 0 1rem;

                p {
                    margin: 0;
                    font-size: smaller;
                }

                &--header {
                    text-transform: uppercase;
                    height: 25px;
                    font-size: 10px;
                    color: rgb($color-text, 0.4);
                }

                &--line:not(:last-child) {
                    grid-column: 1 / -1;
                    height: 2px;
                    border-bottom: 1px solid $color-border-subtle;
                    width: 100%;
                }

                &--line + div {
                    grid-column: 1;
                }
            }

            &--table-multiselect {
                .icon {
                    color: var(--color-text-subtle) !important;
                    margin-left: 0.3rem;
                    font-size: medium;
                }

                &-checkbox {
                    -webkit-appearance: none;
                    width: 14px;
                    height: 14px;
                    display: inline-block;
                    background: #FFFFFF;
                    border: 1px solid rgba(220, 220, 225, 1);
                    border-radius: 3px;

                    &:after {
                        content: '';
                        display: inline-block;
                        position: relative;
                        top: -3px;
                        left: 4px;
                        width: 5px;
                        height: 12px;
                        border-bottom: 3px solid #fff;
                        border-right: 3px solid #fff;
                        -webkit-transform: rotate(45deg);
                    }
                }

                input[type=checkbox]:checked {
                    background-color: var(--color-brand);
                    outline: none;
                    border: 1px solid $color-brand-dark;
                }

                input:focus, input:active {
                    outline: none;
                }

                input:hover {
                    border: 1px solid rgba($color-brand, 1);
                }
            }

            &--ap-dashboard {
                .h4 {
                    margin-bottom: 0;
                }

                &--loc-class_info {
                    display: flex;
                    align-items: flex-start;
                    height: 3rem;

                    &--link {
                        align-self: end;

                        button {
                            color: var(--color-brand) !important;
                        }
                    }
                }
            }

            &--reports {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1em;
                color: $dark-text-emphasis;
                &--figures-overview {
                    &-title {
                        font-family: $font-family-extra-bold;
                        font-weight: $font-weight-bold;
                        font-size: $font-size-sm;
                    }
                    &-top-level-figures {
                        display:grid;
                        grid-template-columns: 1fr 0.1fr 0.75fr;
                        grid-gap: 10px;
                        img{
                            height:2rem;
                        }
                        &-current-figure-values{
                            font-family: $font-family-extra-bold;
                            font-size: 2rem;
                            line-height: 2rem;
                            font-weight: $font-weight-bold;
                        }
                        &-past-figure-values{
                            font-size: 0.5rem;
                            line-height: 1rem;
                        }
                    }
                    &-secondary-figures {

                    }
                    &-tertiary-figures {
                        font-weight: $font-weight-bold;
                        font-size: $font-size-sm;
                    }
                }
            }
        }

        &--main-register {
            background-color: #6AC7BD !important;

            &--main-title {
                font-family: $font-script;
                color: white;

                &-subtext {
                    font-family: $font-family-header;
                    color: $color-text;
                    font-weight: $font-weight-semibold;
                    padding: 0 1rem;
                }
            }

            .form--field {
                background: transparent !important;

                &--input {
                    border-radius: 10px;
                    background: rgb(white, 0.1);
                    color: white;

                    &::placeholder {
                        color: rgb(white, 0.5);
                    }

                    &:hover,
                    &:focus {
                        border-color: white !important;
                    }
                }
            }

            .accordion__header {
                padding: 1em 3em 0em 3em;
            }

            .accordion__body {
                padding: 0em 3em 1em 7em;
                margin-top: -2rem;

                &-subtext {
                    color: $color-text;
                    margin-bottom: 0.8em;
                }
            }
        }

        &--main-login {
            margin: 10em auto;

            &--form {
                margin: 1em 0;

                &--2fa {
                    margin: 2rem;
                    padding: 1rem;
                    background-color: var(--color-background);

                    &-error {
                        border: 1px solid var(--color-error);
                    }

                    &--inputs {
                        display: flex;
                        gap: 7px;
                        margin: 2em 0;

                        .form--field {
                            width: auto;
                            margin: 0;

                            &--input {
                                padding: 0.3em;
                                margin-left: 0;
                                width: 100%;
                                text-align: center;
                                font-size: 1em;
                                font-weight: $font-weight-bold;
                                border: 1px solid $color-border-subtle;
                                background-color: var(--color-background-subtle);

                                &:hover,
                                &:focus {
                                    border-color: $color-text-light !important;
                                }
                            }
                        }
                    }
                }
            }

            &--title {
                text-align: center;
            }

            &--buttons {
                text-align: center;
            }
        }

        .main-pagination {
            width: 100%;
            height: 3em;
            padding-top: 0.1666em;
            display: flex;
            align-items: flex-start;
            font-size: 0.8571em;

            &--pages {
                display: inline-flex;
                gap: 0.3333em;
                margin-right: 1em;

                &--page {
                    font-weight: $font-weight-semibold;
                    cursor: pointer;

                    &__current {
                        color: var(--color-brand);
                        font-weight: $font-weight-bold;
                    }

                    &__relative {
                        position: relative;
                        top: -0.4em;

                        &__first::before {
                            @include icon(double-chevron-right);
                        }

                        &__last::before {
                            @include icon(double-chevron-left);
                        }

                        &__prev::before {
                            @include icon(chevron-left);
                        }

                        &__next::before {
                            @include icon(chevron-right);
                        }
                    }

                    &__disabled {
                        color: var(--color-text-subtle);
                        cursor: default;
                    }
                }
            }

            &--label {
                color: var(--color-text);
                font-style: italic;
            }
        }
        &--pdf {
            font-family: $font-family-base;
            img {
                width: 100%;
            }
            &--header {

            }
            &--body {

            }
            &--footer{

            }
        }
    }

    .main-footer {
        background: var(--color-accent);
        color: var(--color-text-light);
        font-size: 0.5555em;
        margin: auto 0 0;

        &--content {
            height: 4rem;
            display: flex;
            align-items: center;
            gap: $grid-gutter-width;

            a {
                color: inherit;
                font-weight: inherit;
            }

            &--logo {
                &--img {
                    height: 2.6666rem;
                    display: block;
                }
            }

            &--smallprint {
                margin: 0 auto 0 0;
            }

            &--nav {
                display: flex;
                gap: 3ch;
                font-size: 1.2em;
            }
        }
    }
}
@media (max-width: 850px) {
    .page-wrapper {
        .main-body--main-content {
            padding:0 !important;
            margin: 0 0.5rem !important;

            &--table-filter {
                width: 95% !important;
                overflow-x:auto;
            }
            &--enquiry-details {
                grid-template-columns: 1fr !important;

                &--conversation_incoming {
                    margin-left: 0 !important;
                }
        }
    }
    .main-body--sidebar {
        padding: 0.5rem !important;
        width: 30%;
    }
    .main-body--sidebar--logo {
        background-image: url("/images/logo-mini.svg") !important;
        background-size: 45% !important;
        padding: 2rem 0 !important;
        margin-bottom: 1rem;
    }
    .form--field--label {
        flex-basis: 6em !important;
    }


}
#redemption-table{
    display:none;
}


}
