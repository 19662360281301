@use 'sass:math';
@use 'sass:map';

@font-face {
    font-family: icons;
    src: url('/fonts/icons.woff2') format('woff2');
    font-display: swap;
}

.page-wrapper {
    .icon {
        @include icon;

        @each $name, $glyph in $icons {
            &__#{$name} {
                display: inline-block;
                line-height: 1;
                vertical-align: middle;

                &::before {
                    @include icon($name);
                }
            }
        }
    }
}
